.login-form {

	margin: 0 auto;
	max-width: 400px;
	background-color: #fff;

	fieldset {
		border: 1px solid $primary-color;
		padding: $vertical-unit;
		background-color: #fff !important;
	}

	legend {
		color: $primary-color;
	}

	.input-field {
		margin-bottom: 0.5 * $vertical-unit;
		display: flex;
		label {
			display: inline-block;
			@include type-setting(-1);
			width: 100px;
		}

		input {
			padding: 0.25 * $vertical-unit;
			background-color: $light-grey;
			border: 0;
			width: auto;
			margin: 0;
		}

	}

	.button {
		@include type-setting(-1);
		margin-left: 100px;
	}

	.message--auth-failed {
		color: $primary-color;
		@include type-setting(-1);
		margin-bottom: 0.5 * $vertical-unit;
	}
}
