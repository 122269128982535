/**
 * src/css/molecules/_search-form.scss
 *
 * @author JohannesBraun <j.braun@agentur-halma.de>
 * @package data-al
 */
.search-form {
	display: flex;
	align-items: stretch;
	height: 42px;
	width: 100%;
	border: 1px solid $primary-color;
	padding: 0;

	&__input {
		flex: 1 0 auto;
		border: 0;
		outline: 0;
		// background-color: tint($primary-color, 75%);
		background: none;
		padding: 0 0.5 * $gutter;
		height: 100%;
		color: $primary-color;
		-webkit-appearance: none;
	}

	&__submit {
		width: 50px;
		@include button;

		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		height: 100%;
		margin: 0;
		padding: 0;
		display: flex;
		background: none;

		.icon {
			margin: auto;
			width: 1.5em;
			height: 1.5em;
			color: $primary-color;
		}
	}

	@include for-size (tablet-landscape-up) {

		height: 26px;
		border: 1px solid #fff;

		&__input {
			max-width: 5rem;
			transition: 150ms ease-in;
			color: #fff;

			&:focus {
				max-width: 8rem;
			}

		}

		// Keeps the input large even when focusing the submit button (FF, Chrome, Safari, iOS atm)
		&:focus-within .search-form__input {
			max-width: 8rem;
		}

		&__submit {
			background-color: none;
			width: 2rem;
			overflow: hidden;

			&:hover, &:focus {
				/* background-color: transparent !important; */
				// .icon {
				// 	color: $primary-color;
				// }
			}

			.icon {
				width: 1em;
				height: 1em;
				color: #fff;
			}
		}
	}
}
