/**
 * src/css/molecules/_testimonial.scss
 *
 * @author Johannes Braun
 * @package data-al
 */

.testimonials {
	list-style: none;
}

	.flickity-button {
		top: 50%;
		transform: translateY(-50%) scale(2.4);
		background-color: transparent;
		&:hover, &:focus, &:active {
			background-color: transparent;
		}

		path {
			fill: $primary-color;
			stroke-width: 5;
			stroke-linecap: square;
		}
	}
	.flickity-prev-next-button.previous {
		left: 0;
	}
	.flickity-prev-next-button.next {
		right: 0;
	}

.testimonial {
	display: flex;
	@include type-setting(2);

	width: 100%;
	// height: auto;
	// height: 100%;
	// min-height: 200px;
	padding-left: 3 * $gutter;
	padding-right: 3 * $gutter;
	text-align: center;

	&__inner {
		margin-top: auto;
		margin-bottom: auto;
	}


	&__image {
		border-radius: 50%;
	}

	&__text {
		&::before {
			content: '„';
		}
		&::after {
			content: '“';
		}
	}

	&__name {
		@include type-setting(0);
		font-weight: bold;
		font-style: normal;
	}
}

