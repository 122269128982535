/**
 * src/css/components/_mood.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de.>
 * @package data-al
 */

.mood {
    flex: 1 0 auto;
    height: 60vh;
	background-position: center top;
	background-size: cover;
	background-repeat: no-repeat;
    overflow: hidden;
    position: relative;

    .curve svg {
        position: absolute;
        width: auto;
		min-width: 110%;
        height: 20%;
        bottom: -2px;
        left: 50%;
		transform: translate(-50%, 0);
    }

    &--smaller {
        height: 40vh;
		background-position: center bottom;
    }
}
