// Card
//
// A Card component.
//
// .card--no-icon 	- Variant without an icon
//
// Markup:
// <div class="card {{modifier_class}}">
// 		<svg class="card__icon icon" viewBox="0 0 500 500">
// 			<path class="st1" d="M390 190v-59.7c0-16.7-13.6-30.3-30.3-30.3H230V80.6c0-16.9-13.7-30.6-30.6-30.6h-119C63.6 50 50 63.6 50 80.4v325.1C50 430 70 450 94.6 450c25.1 0 45.4-20.3 45.4-45.4V230.2c0-22.2 18-40.2 40.2-40.2H390" id="path7"/><path class="st1" d="M390 190h30.1c16.5 0 29.9 13.4 29.9 29.9v200c0 16.6-13.5 30.1-30.1 30.1H95.2" id="path9"/><path class="st" d="M310 130H134.1c-29.9 0-54.1 24.2-54.1 54.1V370" id="path11" style="fill:none;stroke:#b71547;stroke-width:15;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10"/><path class="st" d="M110 410V209.9c0-27.5 22.3-49.9 49.9-49.9H350" id="path13" style="fill:none;stroke:#b71547;stroke-width:15;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10"/>
// 		</svg>
// 		<div class="card__title headline">Ihr Team-Player</div>
// 		<div class="card__body">Ob Koordination, Kommunikation, Qualitätsmanagement, Ressourcenverwaltung oder Datenabgleich von unterwegs – Data-AL ist ein zuverlässiger und flexibler Sparringpartner. <br></div>
// 		<footer class="card__footer">Da ist kundenorientierter Service garantiert!</footer>
// </div> 
//
// Styleguide  Components.card
.card {

	display: flex;
	flex-direction: column;
	background-color: #fff;

	padding-top: 5 * $vertical-unit;
	padding-bottom: $vertical-unit;
	padding-left: 1.5 * $gutter;
	padding-right: 1.5 * $gutter;

	position: relative;
    // max-width: 20rem;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0;
	margin-top: 3rem; 		// adjust for icon 

	transition: 500ms ease-in;

	@include for-size(tablet-portrait-up) {
		// max-width: none;
		// margin-left: 0;
		// margin-right: 0;
	}

	.card__icon {
		align-self: center;
		position: absolute;
		top: -3rem;
		width: 7 * $vertical-unit;
		height: 7 * $vertical-unit;
		fill: none;
		stroke-width: 15;
		stroke-linecap: round;
		stroke-linejoin: round;
	}

	.card__intro {
		font-weight: bold;
		// font-style: italic;
		margin-bottom: $vertical-unit;
		text-align: left;
	}

	.card__title {
		font-weight: bold;
		text-align: left;
		@include type-setting(2, 1);
	}

	.card__body {
		margin-bottom: $vertical-unit;
	}

	.card__footer {
		font-weight: bold;
	}
}

.card--no-icon {
	padding-top: 0 * $vertical-unit !important;

	.card__icon {
		display: none;
	}

	.card__title {
		position: relative;
		top: -0.75 * $vertical-unit;
		@include type-setting(4);
		line-height: 1;
	}
}
