/**
 * src/css/molecules/_partner.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package data-al
 */

.partner {
    background-color: $light-grey;
    padding: $gutter;
    height: 100px;
}

.partner__link {
    display: block;
    text-align: center;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}

.partner__logo {
    object-fit: scale-down;
    max-width: 100%;
    max-height: 100%;

	transition: 150ms;
	filter: grayscale(1);

}

.partner:hover .partner__logo {
	filter: grayscale(0);
}

.partners {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; //space-between;
    list-style-type: none;
    margin-top: 3 * $vertical-unit;
	margin-bottom: 6 * $vertical-unit;
	@include for-size(tablet-landscape-up) {
		margin-left: -$gutter;
		margin-right: -$gutter;
	}

    .partner {
        width: 100%;
		margin: 0 $gutter $gutter $gutter;

        @include for-size (tablet-portrait-up) {
            width: calc((100% / 2) - 2 * #{$gutter});
        }

        @include for-size (tablet-landscape-up) {
            width: calc((100% / 3) - 2 * #{$gutter});
        }

        @include for-size (desktop-up) {
            width: calc((100% / 4) - 2 * #{$gutter});
        }
    }
}
