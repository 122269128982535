/**
 * src/css/components/_download.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package data-al
 * @version 2020-07-01
 */

.download {
	display: flex;
	flex-direction: column;
	align-items:: center;
	border: 2px solid $primary-color;
	padding: $gutter;
	// max-width: 40rem;
	margin: 3 * $gutter auto;
	text-align: center;

	.download__cta {

		margin-top: $gutter;

		.button {
			display: flex;
			align-items: center;
			margin: 0 -20px;

			span {
				display: block;
				margin: 0 10px;
			}
		}


		.button__icon {
			display: none !important;

			svg {
				width: 2rem;
				height: 2rem;
				color: #fff;

				path {
					stroke: currentColor!important;
					stroke: #fff !important;
					stroke-width: 10;
					fill: transparent;
				}
			}
		}

		a {
			color: currentColor;

			&:hover {
				color: $primary-color;
			}
		}
	}
}
