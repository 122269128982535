// Button
//
// These clicky lil' bastards
//
// .button--primary 	- A button variant that stands out a bit more
//
// Markup:
// <button class="button {{modifier_class}}">Click me</button>
//
// Styleguide Components.button
.button {
	@include button($light-grey);
}

.button--primary {
	@include button($primary-color);
}
