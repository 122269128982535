/**
 * partials/_common.scss
 *
 * Common styles, like e.g. resets, basic styles...
 */
*, *::before, *::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

img, video {
	max-width: 100%;
	height: auto;
}

video {
	margin-bottom: $vertical-unit;
}

// Fix jump-links not to be covered by fixed header
// https://css-tricks.com/fixed-headers-and-jump-links-the-solution-is-scroll-margin-top/
.main-container [id] {
	scroll-margin-top: calc(#{$header-height} + #{$gutter});
}

// Let SVG paths all have the current element's color
// https://css-tricks.com/cascading-svg-fill-color/

symbol {
    path, circle {

        stroke-width: 15;
        stroke-linecap: round;
        stroke-linejoin: round;
        fill: none;

        &.st {
            stroke: $primary-color;
            z-index: 100;
        }
    }
}

svg.icon {
	stroke: currentColor;
	display: inline-block;
	width: 1em;
	height: 1em;

    &--xxl {
        width: 4rem;
        height: 4rem;
    }
}

#anydesk,
#teamviewer,
svg.icon.icon--filled {
	path {
		stroke: none !important;
		fill: $primary-color !important;
	}
}


#DotM {
    stroke: none;
    fill: tomato;
}
// Magnifying glass solid
#magnifying-glass-solid-path {
    fill: currentColor;
}

#plus path {
	stroke: none;
	fill: $primary-color;
}

#map path {
	stroke-width: 1;
}

