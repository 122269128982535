.zertifikat {
	margin: $gutter*6 0 0 0;
	display: flex;
	flex-direction: row-reverse;
	width: 100%;

	&__aside {
		display: flex;
		flex-direction: column;
		width: calc(33.3% - #{$gutter});
		margin-left: $gutter*3;
	}

	&__headline {
		width: 100%;
		padding-top: 1em;
		@include type-setting(3, 1);
		color: $primary-color;
		align-self: flex-end;
	}

	&__text {
		@include type-setting(1, 1);
		width: 100%;
		word-wrap: normal;
		text-overflow: ellipsis;
		align-self: flex-end;
	}

	&-figure {
		flex-direction: column;
		width: 60%;

		&__image {
			//outline: 1rem solid $light-grey;
			width: 100%;
			height: auto;
		}

		&__text {
			margin-top: 1rem;
			//margin-left: $gutter;
			@include type-setting(0);
			font-style: italic;
		}

		&__description {
			width: 100%;
		}
	}
}
