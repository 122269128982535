/**
 * src/css/molecules/_stat.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package data-al
 */
.stats {
    display: flex;
    justify-content: space-between;
	flex-wrap: wrap;

}
.stat {
	width: 100%;
	margin-bottom: 2 * $vertical-unit;
	@include for-size(tablet-portrait-up) {
		width: calc(100% / 3);
	}
    text-align: center;
    em, i {
        font-size: 5em;
        line-height: 1;
        font-weight: bold;
        font-style: normal;
		// -10px: Empirically determined to compensate for missing descenders of
		// digits only text
        margin: 0.5 * $vertical-unit 0 -10px 0;
        display: inline-block;
    }

    &.counter-finished {
        animation: flash 0.9s ease-in-out 1;
    }
}

@keyframes flash {
    0% {
        transform: scale(0.95);
    }
    66% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}
