/**
 * src/css/components/_timeline.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package data-al
 */

.tl-wrapper {
	margin-left: calc(50% - 50vw);
	margin-right: calc(50% - 50vw);
	background-color: $light-grey;
	padding: 3 * $vertical-unit 0;
}

.timeline {

	max-width: $inner-bound-width;
	margin: 0 auto;

	overflow: hidden;
	position: relative;
	padding-left: 8 * $gutter;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: calc(2.5 * #{$gutter} - 1px);
		border-left: 3px solid $primary-color;
	}

}

.timeline-item {

	position: relative;
	background-color: #fff;
	margin: $vertical-unit 0;
	padding: $gutter;

	&::after {
		content: '';
		position: absolute;
		top: 30px;
		width: 0px;
		height: 0px;
		border-style: solid;
		border-color: rgba(255, 255, 255, 0);
		border-width: 14px 20px;
		border-right-color: rgba(255, 255, 255, 1);
		left: -40px;
	}

}

.timeline-item__year {
	position: absolute;
	left: -4rem;
	top: 0;
	margin-left: -3.5rem;
	background-color: $primary-color;
	color: #fff;
	display: inline-block;
	width: 4rem;
	height: 4rem;
	line-height: 4rem;
	border-radius: 50%;
	text-align: center;
	z-index: 3;
	overflow: hidden;
}

// .timeline-item:nth-child(odd) .timeline-item__year {
// 	right: calc(-40% - 2rem);
// }
//
// .timeline-item:nth-child(even) .timeline-item__year {
// 	left: calc(-40% - 0rem);
// }
//
