.solutions {
    padding: 3 * $vertical-unit 0;
    // background-color: $light-grey;
}
#solutions-infographic {

    display: none;

    width: 100%;
    max-width: 100%;
    min-height:600px;

    text, tspan {
        font-family: Univers!important;
    }

    path {
      stroke-width: 1.5px;
    }
    .dot {
      stroke-width: 2px;
      stroke: $primary-color;
    }

}

@include for-size (tablet-landscape-up) {
		.solutions {
	    overflow: hidden;
		}

    .solutions__text {
        display: none;
    }
    #solutions-infographic {
        display: block;
    }
}

