/**
 * src/css/components/_needs.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package data-al
 */
.needs {
	width: 100%;
	height: auto;
	margin-top: 3 * $vertical-unit;
	
	.need {
		font-family: Univers;
		@include type-setting(2, 0);

		@include for-size(tablet-portrait-up) {
			@include type-setting(3, 0);
		}

		@include for-size(tablet-tablet-up) {
			@include type-setting(4, 0);
		}

		color: $dark-grey;
		font-weight: 100;

		strong, b {
			color: $primary-color;
			font-weight: bold;
		}

		text-align: left!important;
		// width: 100%;
		margin: 10px 0;
		position: relative;

		&:nth-child(1) {
			left: 35%;
		}

		&:nth-child(2) {
			left: 25%;
		}

		&:nth-child(3) {
			left: 45%;
		}

		&:nth-child(4) {
			left: 35%;
		}

		&:nth-child(5) {
			left: 27%;
		}
	}
}
