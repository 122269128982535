/**
 * _settings.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package data-al
 */



/**********
 * Colors *
 **********/
$primary-color: #b71547;
$accent-color: lime;
$light-grey: #f2f2f2;
$medium-grey: #808080;
$dark-grey: #4D5356;
$text-color: #4c5256;
$error-color: #c00000;



/************
 * Measures *
 ************/

$gutter: 1rem;
$header-height-small: 76px;
$header-height: 85px;
$inner-bound-width: 47rem;
$modular-scale-ratio: $major-third;
$search-row-height: 64px;

/********************
 * Options/Switches *
 ********************/
$debug-base-line-grid:false;

