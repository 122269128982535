/**
 * libhalma/_rwd.scss
 *
 * Breakpoints, media queries, responsive webdesign
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package libhalma
 */

// See: https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862

///////////////////
//  Breakpoints  //
///////////////////

$bp1: 600px !default;
$bp2: 900px !default;
$bp3: 1200px !default;
$bp4: 1800px !default;

@mixin for-size ($size) {
	@if $size == phone-only {
		@media (max-width: #{$bp1} - 1) {
			@content;
		}
	}
	@else if $size == tablet-portrait-up {
		@media (min-width: #{$bp1}) {
			@content;
		}
	}
	@else if $size == tablet-landscape-up {
		@media (min-width: #{$bp2}) {
			@content;
		}
	}
	@else if $size == desktop-up {
		@media (min-width: #{$bp3}) {
			@content;
		}
	}
	@else if $size == big-desktop-up {
		@media (min-width: #{$bp4}) {
			@content;
		}
	}
}

