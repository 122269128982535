.target-groups {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: stretch;
}

.target-group {
	width: 100%;
	padding: 1rem;
	margin: 1rem;
	background-color: $light-grey;
	text-align: center;
	// display: flex;
	// align-items: stretch;

	@include for-size(tablet-portrait-up) {
		width: calc(50% - 2rem);
	}

	@include for-size(tablet-landscape-up) {
		width: calc(100% / 3 - 2rem);
	}

}
// .target-group__inner {
// 	padding: 1em;
// 	// margin: auto;
// 	background-color: $light-grey;
// 	text-align: center;
// 	// width: 100%;
// }
