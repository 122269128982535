.news--eyecatcher {
	background-color: $light-grey;
	padding: 0.5rem 0;
	text-align: center;
	//min-height: 96px; // IE11 needs this!
	@include type-setting(-1);
}

.news__text {
	margin-bottom: 0;
	@include type-setting(-1);
	max-width: 30rem;
	margin: 0 auto;
}

.news-post {
	margin-bottom: 4 * $vertical-unit;
	max-width: 40rem;
}

.news-post__figure {
	margin: $gutter 0;
	display: flex;
	justify-content: center;
}

