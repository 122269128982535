/**
 * src/css/components/_media-object.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package data-al
 * @version 2020-01-28
 */
.media-object {

	display: flex;
	flex-wrap: wrap;
	margin-bottom: $vertical-unit;
	text-align: left;
	margin-left: -0.5 * $gutter;
	margin-right: -0.5 * $gutter;

	> * {
		margin-left: $gutter;
		margin-right: $gutter;
	}

	&__body {
		flex-basis: 0;
		flex-grow: 999;
		text-align: left;

		.headline.headline.headline {
			text-align: left;
		}
	}

	&__image {
		width: 100%;
		flex-basis: 50%; // as sane default; will be overriden by CMT Layout-mode
		flex-grow: 1;
		margin-bottom: $vertical-unit;
		min-width: 320px;

		img {
			width: 100%;
			max-width: 100%;
		}
	}

	&__image-caption {
		font-style: italic;
	}
}
