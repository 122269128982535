/**
 * src/css/components/_partners.scss
 *
 * @author Johannes Braun
 * @package data-al
 */


.partners__list {
	list-style: none;
	li {
		margin-top: $vertical-unit;
		margin-bottom: $vertical-unit;
	}
}

.partners__map {
	display: flex;
    align-items: flex-start;
	flex: 0;
}

#map {
	min-width: 100%;
}

.map__legend {
    width: 24em;
    flex: 0 0 auto;
    transition: 150ms ease-in;
    margin-left: 3 * $gutter;

    &.fade-out {
        opacity: 0;
        transform: translateX(100px);
    }

    .partners__list {
        display: flex;
        flex-flow: row wrap;
		margin-top: $vertical-unit;
		margin-bottom: $vertical-unit;
		margin-left: -$gutter;
		margin-right: -$gutter;

        > li {
            width: calc(100% / 2 - 2 * #{$gutter});
			margin-left: $gutter;
			margin-right: $gutter;
			text-align: left;
        }

		p {
			text-align: left;
		}
    }
}

.partners__map {
	display: none;
}
.states {
	display: table;
}

@include for-size(tablet-landscape-up) {
	.partners__map {
		display: flex;
	}

	.states {
		display: none;
	}
}

.states {
	width: 100%;

	th, td, p {
		text-align: left;
		padding: 10px;
	}

	tr.state th {
		background-color: $dark-grey;
		color: #fff;
	}
}
