/**
 * src/css//molecules/_search-results.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package data-al
 */

.search-results {
	list-style-type: none;
}

.search-result {
	margin-bottom: 2 * $vertical-unit;
	// text-align: left !important;
}

