/**
 * src/css/components/_cookie-notification.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package data-al
 */
.cookie-notification {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: auto;

	border-top: 1px solid #fff;
	background-color: $light-grey;
	padding: 1rem;

	display: flex;
	transition: 150ms ease-in;
	z-index: 2;

	@include type-setting(-1);

	&__checkbox-area {
		display: none;
	}
	&__decline,
	&__show-options {
		display: none;
	}

	&__accept {
		@extend .button--primary;
		@include type-setting(-1);
	}

	&__message-area {
		max-width: 100%;
	}

	&__action-area {
		margin-left: auto;
		padding-left: 10px;
		// display:flex;
		justify-content: flex-end;
		margin-top: auto;
		display: flex;

		.cookie-notification__accept {
			margin-top: auto;
		}
	}

	&--accepted {
		opacity: 0;
		transform: translateY(100%);
	}
}
