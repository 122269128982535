/**
 * src/css/components/_tables.scss
 *
 * @author Johannes bBraun <j.braun@agentur-halma.de>
 * @package data-al
 */

 .main-container {
	 table {
	 	width: 100%;
		max-width: 100%;
		border-collapse: collapse;
	 }
	 thead {
	 	td, th {
			background-color: $primary-color;
			color: #fff;
		}
	 }

	 th, td { 
	 	border: 2px solid #fff;
		padding: 1rem;
		vertical-align: top;
		background-color: $light-grey;
	}

	th:first-child, td:first-child {
		border-left: 0;
	}

	th:last-child, td:last-child {
		border-right: 0;
	}
}
