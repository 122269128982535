.cards {

	.cards__intro {
		text-align: left;
		margin-bottom: 5 * $vertical-unit;
		width: 100%;
	}

	> *:not(:last-child) {
			margin-bottom: 5 * $vertical-unit;
	}

	.card__title.headline {
		text-align: left !important;
	}


	@include for-size(tablet-portrait-up) {

		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;

		margin-left: -1 * $gutter;
		margin-right: -1 * $gutter;

		> * {
			width: calc(50% - 2 * #{$gutter});
			flex: 0 0 auto;
			margin-left: 1 * $gutter;
			margin-right: 1 * $gutter;
		}

		.cards__intro {
			width: 100%;
		}
	}


	@include for-size(tablet-landscape-up) {

		.cards__intro,
		> * {
			width: calc(33.3% - 2 * #{$gutter});
		}

		> .card:nth-of-type(2n + 4) {
			margin-left: calc(33.3% + 1 * #{$gutter});
		}

		> .card:nth-of-type(2n) {
			top: -3 * $vertical-unit;
		}

		> .card:nth-of-type(2n + 1) {
			top: 3 * $vertical-unit;
		}
	}
}
