/**
 * src/css/components/_sm-icon.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package data-al
 */
.sm-icon {
	color: #fff;
	align-self: flex-end;
	background-color: $primary-color;
	aspect-ratio: 1/1;
	width: 3rem;
	height: 3rem;
	display: flex;
	box-shadow: 0 0 8px rgba(30, 30, 30, 0.5);

	svg {
		display: block;
		margin: auto;
	}
	svg, path {
		fill: currentColor;
	}

	&:hover svg {
		animation: dip-down 350ms ease-out 1;
	}
}
