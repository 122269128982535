/**
 * src/css/components/_cta.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package data-al
 */
.cta {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: top center;
	color: #fff;
	text-align: center;
    position: relative;
    padding-top: 6 * $vertical-unit;
    background-color: $primary-color;
    //margin-top: 3 * $vertical-unit;
		overflow-x: hidden;

    .curve svg {
        position: absolute;
		width: auto;
		min-width: 110%;
		height: 20%;
        top: -2px;
        left: 50%;
		transform: translate(-50%, 0);
		fill: #fff;
    }

    &__body {
        padding-top: 3 * $vertical-unit;
        padding-bottom: 3 * $vertical-unit;
		padding-left: $gutter;
		padding-right: $gutter;
    }

	.headline {
		color: #fff;
	}
}

// Page 11: "Über uns" has only 2 sections
.page-11 .cta .curve svg {
	fill: $light-grey;
}

.cta__contacts {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	@include for-size(tablet-portrait-up) {
		flex-wrap: nowrap;
	}
}

.cta__contact {

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	text-align: center;
	// margin: 0 5 * $gutter;
	margin: 0 1 * $gutter;

	@include for-size(tablet-landscape-up) {
		margin: 0 4 * $gutter;
	}

	@include for-size(desktop-up) {
		margin: 0 5 * $gutter;
	}

	.icon {
		display: block;
		margin-top: $vertical-unit;
		margin-bottom: $vertical-unit;
		width: 6rem;
		height: 6rem;
	}
}

.cta__label {
	@include type-setting(3);
	color: #fff;
}
