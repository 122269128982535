/**
 * Contentomat layoutmode styles
 */

.cmt-layout .cmt-group.ui-droppable{
	&:empty {
		outline: 2px dashed lightblue;
	}

	&:hover {
		outline: 3px dotted lightblue;
	}
}


