/**
 * src/css/components/_map.scss
 *
 * @author Johannes Braun
 * @package data-al
 */
#map {
	.st0 {
		fill: shade($light-grey, 0%);
		stroke: #fff;
		stroke-miterlimit: 10;
		transition: 150ms;
		cursor: pointer;
	}

	.is-selected {
		fill: $primary-color;
		color: $primary-color;
	}

	.st0:hover {
		fill: $dark-grey;
		color: $dark-grey;
	}

	.is-highlighted {
		fill: $primary-color;
		color: $primary-color;
	}
}

