.specs-group {
	// display: flex;
	// justify-content: center;
	margin-bottom: 2 * $vertical-unit;
}

.specs {

	background-color: $light-grey;

	@include for-size(tablet-portrait-up) {
		&:not(:only-child) {
			witdh: calc(50% - #{$gutter});
			margin: 0 $gutter;
		}
	}

	.headline {
		@include type-setting(1, 0);
		padding: 1rem;
		// text-align: center;
	}

	table {
		width: 100%;
		td, th {
			border-left: 0;
			border-right: 0;
		}

		th {
			color: $primary-color;
			font-weight: bold;
			text-align: left;
		}

		ul {
			padding-left: 20px;
			margin: 0;
		}

		li:not(:last-child) {
			margin-bottom: 0.5 * $vertical-unit;
		}
	}

	// Artifacts from WYSIWYG Editor:
	table + p {
		display: none;
	}
}
