/**
 * src/components/_footer.scss
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package data-al
 */

.main-footer {
	background-color: $dark-grey;
	color: #fff;
	text-align: left;
	padding: 0.5 * $vertical-unit 0;
}

.footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1rem;
	width: 100%;

	flex-wrap: wrap;

	&__address {
		order: 3;
		font-style: normal;
		margin: 0.75rem 0;
	}

	&__menu {
		list-style-type: none;
		display: flex;
		// order: 2;
		margin: 0.75rem 0;

		li:not(:first-child) {
			margin-left: $gutter;
		}

		li:last-child {
			display: none;
		}
	}

	@include for-size(tablet-landscape-up) {
		flex-wrap: nowrap;

		.footer__address {
			order: 1;
		}
	}
}

.footer-nav {
	order: 2;

	a {
		color: #fff;
	}
}

