/**
 * src/css/components/_collapsable_table.scss
 *
 * @author Johannes Braun <j.braun@agentur-hama.de>
 * @package data-al
 */
.collapsable-table {
	position: relative;
	margin-bottom: $vertical-unit;

	&__header {
		background-color: $primary-color;
		text-align: center;
		position: relative;
		display: flex;
		align-items: center;
		justify-items: space-between;
        z-index: 1;
        cursor: pointer;
	}

	&__title {
		@extend .headline--small;
		color: #fff;
		margin: 0;
		cursor: pointer;
		display: block;
		width: 100%;
		padding: 0.5 * $vertical-unit $gutter;
        text-align: left;

		// &::after {
		// 	content: '';
            // background-image: url('/src/icons/arrow_right.svg');
            // background-size: cover;
            // background-repeat: no-repeat;
            // width: 48px;
            // height: 48px;

		// 	position: absolute;
		// 	right: $gutter;
		// 	top: 0.5 * $vertical-unit;
		// 	color: #fff;
		// }
	}

    &__icon {
        width: 48px;
        height: 48px;
        margin-right: $gutter;
        transition: 150ms ease-in 150ms;
		transform:  scaleY(-1);
    }

	&__body {
        overflow: hidden;
        transform: translate(0, 0);
        transition: 350ms;
        // z-index: -1;
        opacity: 1;
	}

    &--is-collapsed {
        .collapsable-table__body {
            height: 0 !important;
            opacity: 0;
            transform: translate(0, -#{$vertical-unit});
        }

        .collapsable-table__icon {
            transform:  scaleY(1);
        }
    }
}
