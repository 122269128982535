/**
 * src/css/atoms/_hotline.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package data-al
 */

// Hotline 
//
// Markup:
// <div class="hotline">
//    <svg class="icon"><use href="#phone"></use></svg>
//    <div class="hotline__number">0731 88 04 95-95<br></div>
// </div>
//
// Styleguide atoms.hotline
.hotline {
    vertical-align: middle;
    text-align: center;
    margin: $vertical-unit 0;

    .icon {
        display: inline;
        vertical-align: middle;
        color: $primary-color;

		width: 2.5em;
		height: 2.5em;

		@include for-size(tablet-portrait-up) {
			width: 3.5em;
			height: 3.5em;
		}

		@include for-size(desktop-up) {
			width: 5em;
			height: 5em;
		}
    }
}

.hotline__number {
    display: inline-block;
	font-size: 2em;
	@include for-size(tablet-portrait-up) {
		font-size: 3em;
	}
	@include for-size(desktop-up) { 
		font-size: 4em;
	}
    line-height: 1;
    font-weight: bold;
    vertical-align: middle;
}
