/**
 * src/css/partials/_typography.scss
 *
 * Typography related styles
 */

/*
 *						Webflow 									Sass 
 *						fs 		lh 									major-third / 1.2
 * 																							fs 		Scale	
 * h2 headline 			48px 	54px 	1.125 		2.4 			.headline--large 		2.441  	4
 * h3 headline 		 	40px 	44px 	1.1 		2 				.headline 				1.953  	3
 * h4 vorteil headline 	30px 	36px 	1.2 		1.5 			.headline--small 		1.563   2
 * zitat 				30px 	36px 	1.2 		1.5 			.blockquote 			1.563  	2
 * fliesstext 			20px 	26px 	1.3 		1 				.body-text 				1 		0
 * footer text 			16px 	20px 	1.25 		0.8 			.small-text 			0.8  	-1
 */


/************
 * Webfonts *
 ************/
@include font-face ('Univers', '../fonts/Univers-LightCn', normal, normal, $file-formats: woff2 woff ttf);
@include font-face ('Univers', '../fonts/Univers-BoldCn', bold, normal, $file-formats: woff2 woff ttf);


html {
	@include fluid-type (font-size, $bp1, $bp3, 14px, 20px);
}

body {
	// Sets up font-size and line-height
	// according to vertical rhythm. 
	// @see mixins in mixins/_vertical_rhythm.scss
	@include type-setting(0);
	color: $text-color;
	font-family: Univers, sans-serif;
}

a {
	color: $primary-color;
	text-decoration: none;
}


p, ul, .body-text {
	@include type-setting(0, 1);
}

p:last-child, .body-text:last-child {
	margin-bottom: 0;
}



.small-text {
	@include type-setting(-1);
}

.big-text {
	@include type-setting(6);
}

.headline {
	@include type-setting(3, 1);
	color: $primary-color;
}

.headline--large {
	@include type-setting(4);
	color: $primary-color;
}

.headline--small {
	@include type-setting(2, 1);
	color: $primary-color;
}



// Compensate for header height
// on link targets
.headline[id] {
	padding-top: $header-height + 32px;
	margin-top: -$header-height - 32px;
}

.cmt-object-content-wrapper > .headline[id] {
	margin-top: 0;
	padding-top: 0;
}



.subheadline {
	@include type-setting(1, 0);
}

.superscription {
	@include type-setting(0, 0);
}

.subheadline:not(:first-child) {
	margin-top: 3 * $vertical-unit;
}

.subheadline {
	margin-bottom: 0.5 * $vertical-unit;
}

.figure {
	margin: $vertical-unit 0 2 * $vertical-unit 0;
}

.figure__caption {
	@include type-setting(0, 0);
	font-style: italic;
}

.figure__copyright {
	@include type-setting(-1, 0);
	font-style: italic;
}

.remark {
	position: relative;
	margin-bottom: $vertical-unit;
}

.remark::before {
	content: 'Anmerkung';
	background-color: #c00000;
	color: #fff;
	@include type-setting(-1, 0);
	padding: 2px 4px;
	position: absolute;
	top: -24px;
	left: 0;
	height: 24px;
	overflow: hidden;
}

.remark mark {
	padding: 2px 4px;
}

body:not(.page-is-left-aligned) {
	.section {

		.headline,
		.subheadline,
		.body-text,
		.figure__caption,
		.remark {
			text-align: center;
		}

		.remark::before {
			left: 50%;
			transform: translateX(-50%);
		}

		ul, li {
			list-style-type: none;
			text-align: center;
		}
	}

	.figure {
		text-align: center;
	}
}

.body-text {
	ul {
		list-style: none;
	}

	li {
		position: relative;
		padding-left: 14px;
		margin-bottom: 0.5 * $vertical-unit;

		&::before {
			content: '';
			width: 6px;
			height: 6px;
			position: absolute;
			top: 7px;
			left: 0;
			border-radius: 50%;
			background-color: $primary-color;
		}
	}
}
