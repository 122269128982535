/**
 * partials/_layout.scss
 *
 * @author: Johannes Braun <j.braun@agentur-halma.de>
 * @package data-al
 */

html {
	height: 100%;
}

body {
	overflow-x: hidden;
	height: 100%;
	margin: $header-height-small 0 0 0;
    @include for-size (desktop-up) {
        margin: $header-height 0 0 0;
    }
	display: block;
	//flex-direction: column;
}

.main-container {
	flex: 1 0 auto;
}

.section {
	overflow-x: hidden;
	overflow-y: visible;
	background-color: #fff;
	min-height: 100px;

	padding-top: 5 * $vertical-unit;
	padding-bottom: 5 * $vertical-unit;

	&--dark {
		background-color: $light-grey;
	}

	&--half {
		background-image: linear-gradient(to bottom, #fff 40%, $light-grey 40%);
	}
}

.inner-bound {
	max-width: $inner-bound-width;
	width: 100%;
	margin: 0 auto;
	padding-left: $gutter;
	padding-right: $gutter;
	@include for-size (tablet-landscape-up) {
		padding-left: 0;
		padding-right: 0;
	}
}

.u-container-break {
	margin-left: calc(50% - 50vw);
	margin-right: calc(50% - 50vw);
}

@include for-size (tablet-portrait-up) {
	.has-sidebar {
		.inner-bound {
			display: flex;
			justify-content: space-between;
		}

		.main-content {
			width: 75%;
		}

		.sidebar {
			width: 20%;
		}
	}
}
