#js-lightbox-overlay {
	position: fixed;
	z-index: 2;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	background-color: #000;

	--space: 0.1rem;
	--padding: #{$header-height-small};
	--color: #{$primary-color};
	@include for-size (tablet-landscape-up) {
		--space: 2rem;
		--padding: #{$header-height};
		--color: #fff;
	}
}

$button-color: var(--color);
$button-background: none;
$button-border: 6px;
$button-size: 3rem;

.js-lightbox-overlay__image {
	height: calc(100% - var(--padding));
	width: 100%;
	margin: var(--padding) 0 0 0;

	object-fit: contain;
	object-position: center;

	@include for-size(tablet-landscape-up) {
		height: calc(100% - var(--space) * 2 - var(--padding));
		width: calc(100% - (#{$button-size} + var(--space) * 2) * 2);
		margin: calc(var(--padding) + var(--space)) calc(#{$button-size} + var(--space) * 2) var(--space) calc(#{$button-size} + var(--space) * 2);
	}
}

.js-lightbox-overlay__button {
	position: absolute;
	background: none;
	width: $button-size;
	height: $button-size;
	color: transparent;
	font-size: 0;
	border: unset;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		outline: grey 2px dotted;
	}

	&[name="close"] {
		top: calc(var(--padding) + var(--space));
		right: var(--space);
		background: $button-background;
		width: $button-size * 1.5;
		height: $button-size * 1.5;
		padding: $button-size / 4;
		border-radius: 50%;

		&::after {
			position: absolute;
			top: 0;
			display: block;
			content: '';
			width: $button-size * 0.6;
			height: $button-size * 0.6;
			margin: $button-size * 0.2;
			transform-origin: center;
			transform: rotate(-45deg) translate(calc(-50% + #{$button-border/2} - #{$button-size * 0.15}), calc(-50% + #{$button-border/2} + #{$button-size * 0.15}));
			border-right: $button-border solid $button-color;
			border-bottom: $button-border solid $button-color;
		}

		&::before {
			position: absolute;
			top: 0;
			display: block;
			content: '';
			width: $button-size * 0.6;
			height: $button-size * 0.6;
			margin: $button-size * 0.2;
			transform: rotate(-45deg) translate(calc(50% - #{$button-border/2} - #{$button-size * 0.15}), calc(50% - #{$button-border/2} + #{$button-size * 0.15}));
			border-left: $button-border solid $button-color;
			border-top: $button-border solid $button-color;
		}
	}

	&[name="next"] {
		top: 50%;
		transform: translateY(-50%);
		right: var(--space);
		background: $button-background;
		width: $button-size * 1.5;
		height: $button-size * 1.5;
		padding: $button-size / 4;
		border-radius: 50%;

		&::after {
			display: block;
			content: '';
			width: 70%;
			height: 70%;
			margin: 15%;
			transform: rotate(-45deg) translate(-15%, -15%);
			border-right: $button-border solid $button-color;
			border-bottom: $button-border solid $button-color;
		}
	}

	&[name="previous"] {
		top: 50%;
		transform: translateY(-50%);
		left: var(--space);
		background: $button-background;
		width: $button-size * 1.5;
		height: $button-size * 1.5;
		padding: $button-size / 4;
		border-radius: 50%;

		&::after {
			display: block;
			content: '';
			width: 70%;
			height: 70%;
			margin: 15%;
			transform: rotate(-45deg) translate(15%, 15%);
			border-left: $button-border solid $button-color;
			border-top: $button-border solid $button-color;
		}
	}
}
