/**
 * src/css/components/_eyecatcher.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package data-al
 */
.fixed {
	position: fixed;
	top: 25%;
	right: 0;
	z-index: 2;
	max-width: 150px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.fixed > * + * {
	margin-top: $gutter;
}

.cmt-layout .fixed {
	width: 150px;
}

.eyecatcher {

	background-color: $primary-color;
	box-shadow: 0 0 8px rgba(30, 30, 30, 0.5);
	z-index: 3;
	color: #fff;
	width: 7rem;

	&__link {
		@include type-setting(0, 0);
		line-height: 1;
		color: currentColor;
		text-decoration: none;
		display: block;
		padding: 15px 8px 15px 8px;
		text-align: center;
	}

	// &__icon {
		// display: none;
	// }

	&:hover svg {
		animation: dip-down 350ms ease-out 1;
	}

	@include for-size(tablet-portrait-up) {
		top: 33%;

		.eyecatcher__link {
			@include type-setting(1, 0);
			line-height: 1;
			padding: 30px 24px 0px 24px;
		}

		.eyecatcher__icon {
			display: inline-block;
			width: 2rem;
			height: 2rem;
		}
	}

	@keyframes dip-down {
		50% {
			transform: translateY(0.2rem);
		}
		100% {
			transform: translateY(0);
		}
	}
}


.eyecatcher--download {
	background-color: #fff;
	color: $primary-color;
	padding-bottom: $gutter;
	display: flex;
	position: relative;
	overflow: hidden;

	.eyecatcher__link {
		transition: 150ms ease-out;
	}
	
	.eyecatcher__icon {
		fill: none;
		stroke: $primary-color;
		// stroke-width: 3px;
		width: 4rem;
		height: 4rem;
	}

	.eyecatcher__text {
		hyphens: auto;
		font-size: 0.70em;
	}
}

.eyecatcher__drawer {
	width: 100%;
	height: 100%;
	padding: 0.5rem 1rem;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: absolute;
	align-items: center;
	background-color: #fff;
	gap: 0rem;
	transition: 150ms ease-out;
	transform: translateY(100%);
	font-size: 0.8125em;
	a {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.icon {
		width: 2.5rem;
		height: 2.5rem;
	}
}

.eyecatcher--download.is-open .eyecatcher__link,
.eyecatcher--download:hover .eyecatcher__link {
	transform: translateY(-100%);
}
.eyecatcher--download.is-open .eyecatcher__drawer,
.eyecatcher--download:hover .eyecatcher__drawer {
	transform: translateY(0);
}
