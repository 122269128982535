/**
 * src/css/components/_hero.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de.>
 * @package data-al
 */

.hero {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	animation: hero-appear 500ms ease-in forwards 1;

	&__title {

		position: absolute;
		background-color: #fff;
		position: absolute;
		bottom: 0;
        left: $gutter;
        right: $gutter;

        @include type-setting(3);
        max-width: 100%;
        padding: 1rem;

        @include for-size (tablet-portrait-up) {
            @include type-setting(3);
            max-width: 10em;
            padding: 2rem;
        }

        @include for-size (tablet-landscape-up) {
            @include type-setting(3);
        }
	}
}

@keyframes hero-appear {
	from {
		transform: translate(0, 4 * $vertical-unit);
	}
	to {
		transform: translate(0, 0);
	}
}
