/**
 * src/css/components/_infobox.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package data-al
 */

.infobox {
	background-color: $light-grey;
	padding: $gutter;
	margin-bottom: $vertical-unit;

	&__title {
		@include type-setting(0, 1);
		color: $primary-color;
	}

	&__body {
		*:last-child {
			margin-bottom: 0;
		}
	}
}

