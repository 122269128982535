.news-slider {
	margin-bottom: $gutter*6;
}

.news-slider-wrapper {
	background-color: #f2f2f2;
}

.news-slide {
	width: 100%;
	height: auto;
	padding: $gutter*3;

	* {
		min-height: 100%;
		height: auto;
	}
}

.news-slide__body {
	display: flex;
	flex-flow: column-reverse;
	gap: 2rem;

	@include for-size(tablet-landscape-up) {
		flex-flow: row-reverse;

		:first-child {
			margin-left: 1rem;
		}

		:last-child {
			margin-right: 1rem;
		}

		@supports (gap: 2rem) {
			:first-child {
				margin-left: inherit;
			}

			:last-child {
				margin-right: inherit;
			}
		}
	}

	.news-slide__info {
		display: flex;
		flex-flow: column;
		flex-basis: 1 50%;
		margin: auto 0;
	}

	.news-slide__figure {
		flex: 0 0 50%;
	}

	.button {
		margin-top: $gutter/2;
		align-self: center;
	}
}

.news-slide__image {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

.news-slide__headline {
	margin-bottom: 1em;
}
