.plus-chain {
	display: flex;
	flex-direction: column;
	// flex-direction: row;
	list-style-type: none;
	align-items: center;

	&__item:not(.plus-chain__item--plus) {
		background-color: $light-grey;
		width: 160px;
		height: 160px;
		padding: 1rem;
		flex: 1 0 auto;
		display: flex;

		> .plus-chain__label {
			margin: auto;
            text-align: center;
		}
	}

	&__item--plus {
		flex: 0 1 1rem;
		position: relative;
		z-index: 1;

		.icon {
			max-width: 4rem;
			max-height: 4rem;
            transform: scale(4);
			z-index: 1;
		}
	}

	@include for-size (tablet-landscape-up) {
		flex-direction: row;
	}
}
