/**
 * mobnav.scss
 *
 * Mobile navigation implementation
 * No visual styling at all. Use as boilerplate
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package mobnav
 * @version 2016-08-24
 */
// @import "bourbon/bourbon";
// @import "_settings";

$mobnav-bg-color: #fff !default;

.mobnav {

	line-height:2em;

	position:fixed;
	overflow-x: hidden;
	overflow-y: auto;

	top:0;
	left:0;
	right:0;
	bottom:0;
	background-color:$mobnav-bg-color;
	transform: translate3d(100%, 0, 0);
	
	transition:250ms ease-in;

	@at-root #{&}__trigger-checkbox {
		display:none;
		
		&:checked ~ .mobnav {
			transform: translate3d(0, 0, 0);
		}
	}

	@at-root #{&}__trigger-label {
		position:fixed;
		top:0;
		right:0;
		display:block;
		cursor:pointer;
	}

	@at-root #{&}__page {
		list-style:none;
		margin:0;
		padding:0;
		min-height:100%;
	}

	@at-root #{&}__row {

		display: flex;

		&.mobnav__row--is-current {
			// color:$accentColor;
		}
		&.mobnav__row--close {
			// color:$accentColor;
		}
	}
	@at-root #{&}__link {
		flex: 1 0 auto;
		max-width:100vw;
		text-overflow:ellipsis;
		white-space: nowrap;
		overflow:hidden;
		display:block;
	}

	@at-root #{&}__button {
		overflow:hidden;
		flex: 0 1 auto;
		display: flex;

		.icon {
			margin:auto 0;
			cursor:pointer;
			fill: currentColor;
			width:2em;
			height:2em;
		}
	}
}

.mobnav__page .mobnav__page {
	background-color:$mobnav-bg-color;
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	height:800px;
	transition:300ms;
	transform: translate3d(100%, 0,  0);
}

.mobnav__page--is-active > .mobnav__page {
	transform: translate3d(0, 0, 0);
}

.mobnav__search {

	width:100%;

	display: flex;

	input[type="search"] {
		flex: 1;
		width:100%;
	}

	button[type="submit"] {
		flex: 0;
		display:inline-block;
	}
}

.mobnav__language-select {
}

.menu-is-open .mobnav {
    transform: translate3d(0, 0, 0);
}
