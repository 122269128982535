/**
 * src/css/components/_nav.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package data-al
 */
.main-header {
	background-color: $primary-color;
	position: fixed;
	z-index: 4;
	top: 0;
	left: 0;
	width: 100%;

	.inner-bound {
		max-width: 100%;
        padding: 0 $gutter;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: $header-height-small;
	}

	&__brand {
		img {
			display: block;
			height: 40px;
		}
	}
}

.mobnav {
    top: $header-height-small;

	&__trigger {
		@extend %button-reset;
        outline: none;

        &::-moz-focus-inner {
            border: none;
        }

		svg {
            transition: 200ms ease-in;
			width: 40px;
			height: 40px;
            path {
                stroke: #fff;
                stroke-width: 10;
                stroke-linecap: round;
                fill: none;
            }
		}
	}

    &__page {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        background-color: #fff;
        // Keep this and the mobnav__link's z-index
        // to avoid that links of lower menu levels
        // will peek through
        z-index: 1;

        .mobnav__page {
            top: $search-row-height; // TODO: Magic Number Alert!
        }
    }

    &__row {
        border-bottom: 1px solid $light-grey;
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 $gutter;

        &--search {
            height: $search-row-height;
        }

        &--drill-up {
                font-weight: bold;
        }

        &--is-current {
            border-left: 4px solid $primary-color;
        }
    }

    &__link {
        // text-transform: uppercase;
        @include type-setting(1);
        display: block;
        color: $text-color;
        transform: translateY(4px); /* Univers is not rendering vertically centered in its bounding-box */
    }

    &__button {
        @extend %button-reset;
        position: relative;
        // width: 48px;
        // height: 48px;

        svg {
            // width: 48px;
            // height: 48px;
            color: $primary-color;
        }

        &--drill-down {
            // left: 5%;
        }

        &--drill-up {
            // right: 5%;
        }
    }
}

.menu-is-open {
    .main-nav__menu {
        transform: translate(0, 0);
    }

    .mobnav__trigger {
        svg {
            transform: rotate(-90deg);
        }
    }
}




// ------------
// Desktop view
// ------------
@include for-size (tablet-landscape-up) {
    .main-header {
        .inner-bound {
            max-width: $inner-bound-width;
            height: $header-height;
            padding: 0;
        }
    }

    .mobnav {
        transform: none;
        position: static;
        width: 100%;
        height: 100%;
        background-color: transparent;
        margin-left: auto;
        width: auto;

        &__trigger {
            display: none;
        }

        &__page {
            flex-flow: row nowrap;
            overflow: hidden;
            background-color: transparent;
            height: 100%;

            .mobnav__page {
                flex-direction: column;
                align-items: flex-start;
                width: auto;
                top: $header-height;
                left: auto;
                right: auto;
                bottom: auto;
                height: auto;
                transform: scale(0);
                transform-origin: top left;
                transition: 150ms ease-in;

                .mobnav__row {
                    width: 100%;
                }

                .mobnav__link {
                    color: $text-color;
                    display: block;
                    padding: 0.5 * $vertical-unit $gutter;
                    &:hover {
                        background-color: $light-grey;
                    }
                }

            }
        }

        &__row {
            border: 0;
            padding: 0;
            width: auto;
            // margin-left: $gutter;
            height: 100%;

            &--has-drill-down {
                cursor: pointer;

                .mobnav__link {
                    padding-right: 0;
                }
            }

            &--drill-up {
                display: none;
            }

            &--search {
                margin-left: $gutter;
                order: 2;
            }

			transition: 100ms ease-in;

            &:not(&--search):hover {
                background-color: #fff;
                .mobnav__link, svg {
                    color: $primary-color;
                }
            }

			&.mobnav__row--is-current {
				border-top: 4px solid #fff;
			}
        }

        &__link {
            @include type-setting(0);
            text-transform: none;
            color: #fff;
            transform: none;
            height: 100%;

            display: flex;
            padding: 0 $gutter;

            > .mobnav__link-title {
                margin: auto;
				height: 1.5rem; // IE11 needs this!
            }
        }

        &__button {
            padding-right: $gutter;
            > svg {
                width: 24px;
                height: 24px;
                transform: rotate(90deg);
                color: #fff;
            }

			&--drill-down {
				left: 0;
				top: -1%;
			}
        }

        // Drop-down
        .is-visible  {

            background-color: #fff;

            .mobnav__page  {
                transform: scale(1);
            }

            .mobnav__link {
                color: $primary-color;
            }

            .mobnav__page .mobnav__link {
                color: $text-color;
            }

            svg {
                color: $primary-color;
            }
        }
    }
}

.download-categories-nav {
	ul {
		display: flex;
		justify-content: flex-start;
		list-style: none;
	}

	li {
		flex: 0 0 auto;
		background-color: $light-grey;
		margin-right: 2px;
		transition: all 150ms ease-in;

		&:hover {
			background-color: $dark-grey;
			color: #fff;
		}

		&.is-current {
			background-color: $primary-color;
			color: #fff;
		}
	}

	a {
		display: block;
		color: currentColor;
		font-weight: bold;
		padding: 0.5em 1em;
	}
}

.u-centered-column {
	text-align: center;
}

.downloads-table .u-centered-column {
	max-width: 160px;
	width: 160px;
}

.downloads-login-button {
	margin-top: 6 * $vertical-unit;
	text-align: center;

}
