/**
 * src/css/partials/_form.scss
 *
 * @author JohannesBraun <j.braun@agentur-halma.de>
 * @package data-al
 * @version 2020-01-21
 */

form {

	font-size: 1rem;
	line-height: 1.5em;
	padding: 1rem;
	// background-color: $light-grey;

	> fieldset + fieldset {
		margin-top: 3rem;
	}

	fieldset {
		// border: 1px solid $primary-color;
		// border: 1px solid $dark-grey;
		border: 0;
		padding: 1rem;
		background-color: $light-grey;
	}

	legend {
		color: $primary-color;
		padding: 0 0.5em;
	}

	.message {
		margin-bottom: 1.5rem;
	}

	label {
		display: block;
		margin: 4px 0;
	}

	input, textarea {
		border: 1px solid $medium-grey;
		padding: 0.25rem 0.5rem;
		background-color: #fff;
		width: 100%;
		font-size: 1rem;
		font-family: Univers;
		color: $text-color;
	}

	textarea {
		min-height: 160px;
	}

	.form-grid {
		// > .form-field + .form-field {
		// 	margin-top: 1.5rem;
		// }

		display: grid;
		gap: 1rem;
		grid-template-columns: repeat(2, 1fr);

		> .form-field {
			margin: 0;
		}

		.form-field--message {
			grid-column: 1 / -1;
		}

		.form-field--request-type + .form-field {
			grid-column: 1 / span 1;
		}
	}

	.form-field {
		&--invalid {
			input, textarea {
				background-color: rgba(firebrick, 0.2);
				border-color: firebrick;
			}
			label {
				color: firebrick;
			}
		}
	}

	.action-area {
		margin-top: 1.5rem;
		display: flex;

		[type=submit] {
			margin-left: auto;
		}
	}
}



.form-field--select {
	select {
		display: block;
		font-weight: 400;
		padding: 0.6em 1.4em 0.5em 0.8em;
		width: 100%;
		max-width: 100%;
		box-sizing: border-box;
		margin: 0;
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		background-color: #fff;
		background-image: url('/dist/img/triangle.svg');
		background-repeat: no-repeat;
		// background-position: right -0.7em top 50%;
		background-size: 0.75rem;
		background-position: 98% 50%;
		box-shadow: unset;
		border: 1px solid $dark-grey;
	}
	select::-ms-expand {
		display: none;
	}
	select:hover {
		border-color: #888;
	}
	select:focus {
		border-color: #aaa;
		// box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
		// box-shadow: 0 0 0 3px -moz-mac-focusring;
		color: #222;
		outline: none;
	}
	select option {
		font-weight: normal;
	}
}

.message {
	line-height: 1.5em;
	padding: 0.5em;

	&--error {
		font-weight: bold;
		background-color: rgba(firebrick, 0.2);
		color: firebrick;
	}
}
