/**
 * _sitemap.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package data-al
 */
.sitemap {

    background-color: $dark-grey;
    // padding-top: 3 * $vertical-unit;
    // padding-bottom: 3 * $vertical-unit;
    // border-top: 1px solid rgba(255, 255, 255, 0.5);

    a {
        color: #fff;
    }

	.inner-bound {
    	padding-top: 3 * $vertical-unit;
		// padding-bottom: 3 * $vertical-unit;
		// border-bottom: 1px solid rgba(255, 255, 255, 0.5);
	}

	.inner-bound > ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 0;
		margin-bottom: 0;
		margin-left: -$gutter;
		margin-right: -$gutter;

		> li {

			width: calc(100% / 2 - 2 * #{$gutter});
			@include for-size(tablet-portrait-up) {
				width: calc(100% / 4 - 2 * #{$gutter});
			}

			margin: 0 $gutter 2 * $vertical-unit $gutter;

			> a {
				text-transform: uppercase;
				font-weight: bold;
				display: inline-block;
				margin-bottom: 12px;
				padding-bottom: 0px;
				width: 100%;
				border-bottom: 1px solid rgba(255, 255, 255, 1);
			}
		}
	}
}

