/**
 * src/css/components/_gallery.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package data-al
 */

.gallery {
	list-style-type: none;
}

.gallery--tiles {

	display: flex;
	justify-content: flex-start;
	margin-left: -$gutter;
	margin-right: -$gutter;
	margin-bottom: 2 * $vertical-unit;
	flex-wrap: wrap;
	flex-direction: row;
	align-content: flex-end;
	justify-content: left;

	> figure {

		display: flex;
		flex-direction: column;
		height: auto;
		width: 100%;

		text-align: center;
		margin: 0 $gutter $vertical-unit $gutter;
		background-color: #fff;

		box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);

		@include for-size(tablet-portrait-up) {
			width: calc(100% / 2 - 2 * #{$gutter});
		}

		@include for-size(tablet-landscape-up) {
			width: calc(100% / 3 - 2 * #{$gutter});
		}

		img {
			transition: 150ms ease-in;
			object-fit: contain;
			object-position: center center;
			height: 100%;
			width: 100%;
		}

		&:hover img {
			transform: scale(0.9);
		}

		.gallery-image-wrapper {
			border: none;
			background: none;
			flex: 0 1 auto;
			height: 300px;
			display: flex;
			padding: 20px;
		}

		figcaption {
			border-top: 1px solid rgba(0, 0, 0, 0.02);
			text-align: left;
			background-color: rgba(0, 0, 0, 0.05);
			padding: $gutter;
			flex: 0 0;
			height: 100%;
			margin-top: auto;
		}

		p {
			margin: 6px 0 0 0;
		}
	}
}

.framed-carousel {
	position: relative;

	width: 292px;
	height: 168px;

	margin: 10vh auto 7vh auto;


	.gallery--carousel {
		width: 100%;
		height: 100%;
		padding: 12px 30px 18px 30px;
		overflow: hidden;;
	}

	@include for-size(tablet-portrait-up) {
		width: 540px;
		height: 540px * 0.5753;

		.gallery--carousel {
			padding: 21px 64px 33px 64px;
		}
	}

	@include for-size(tablet-landscape-up) {
		width: 800px;
		height: 800px * 0.5753;

		.gallery--carousel {
			padding: 32px 96px 49px 96px;
		}
	}

	@include for-size(desktop-up) {
		width: 940px;
		height: 940px * 0.5753;

		.gallery--carousel {
			padding: 38px 115px 57px 115px;
		}
	}

	.flickity-page-dots {
		display: none;
	}
}

.gallery--carousel {
	padding-bottom: 619 / 989 * 100%;
	height: 0;

	.gallery-image {
		width: 100%;
		overflow: hidden;
		filter: brightness(1);
	}
}

.computer-frame {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url('/dist/img/computer_frame.png');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	// z-index: 1000;
	pointer-events: none;
}

.flickity-prev-next-button.previous {
	left: -0.5rem;
}

.flickity-prev-next-button.next {
	right: -0.5rem;
}
