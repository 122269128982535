.newsletter-form {
	text-align: center;
	margin: 0 auto $gutter*4 auto;
	padding-top: $gutter*4;

	&__headline {
		width: 100%;
	}

	&__text {
		//text-align: left;
		flex: 0 100%;
		margin: $gutter $gutter/4;
	}

	&__checkbox-box {
		margin: $gutter $gutter/4;
		display: flex;
		justify-content: flex-start;
	}

	&__checkbox {
		position: relative;
		margin-right: 0.5 * $gutter;
		width: 0.9rem;
		height: 0;
		margin-top: 0.16em;
		color: $primary-color;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 0;
			padding-bottom: 98%;
			border: $primary-color solid 1px;
		}

		&::after {
			content: " ";
			display: block;
			padding: 0.2em;
			font-size: 0.9em;
			line-height: 0.85;
		}

		#newsletter-form-confirm-sign-up:checked ~ &::after {
			content: "✓";
		}

		#newsletter-form-confirm-sign-up:focus + & {
			outline: 2px dotted $dark-grey;
		}

		&-label {
			text-align: left;
			flex: 1 100%;
		}
	}

	.newsletter-form-inner {
		// display: flex;
		// flex-flow: row wrap;
		width: 100%;
		margin: auto;

		// display: grid;
		// grid-template-rows: 1fr 1fr 1fr 1fr;
		// grid-template-columns: 1fr 1fr;

		> .row + .row {
			margin-top: 0.5 * $vertical-unit;
		}

		.row {
			width: 480px;
			max-width: 100%;
			margin: auto;

			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			&:last-child {
				justify-content: center;
			}

			.form-input {
				width: 100%;
				margin-left: #{0.5 * $gutter};
				margin-right: #{0.5 * $gutter};
				flex: 1 0 10rem;
			}

			// &--name {
			// 	.form-input {
			// 		flex
			// 	}
			// }

			input[type=email], input[type=text] {
				margin: 0;
			}

			input[type=checkbox] {
				margin-right: 1em;
			}

			label:not([class^=newsletter-form__checkbox]) {
				display: block;
				padding: 0;
			}
		}
	}

	&__input {
		@include type-setting(-1);
		flex: 1 45%;
		margin: $gutter/4;
		border: 1px solid $primary-color;
		padding: 0.5rem 0.5rem;

		&:invalid {
			box-shadow: none !important;
		}

		&:last-of-type:nth-of-type(odd) {
			width: 100%;
			flex: 0 100%;
		}

		&--signout {
			flex: 0 100%;
		}
	}

	&__button {
		margin: auto;
		margin-top: $gutter;
	}

	&-layoutmode {
		p, h1 {
			min-height: 1em;
			flex-basis: 100%;
		}

		.newsletter-form__checkbox-box {
			flex: 0 100%;
		}

		.newsletter-form__checkbox-label {
			min-width: 30ch;
		}
	}

	&__error {
		@include type-setting(-1);
		color: #fff;
		padding: $gutter/2;
		margin: $gutter/4;
		margin-bottom: $gutter;
		background-color: $primary-color;
		flex: 0 100%;
	}

	&__valid {
		@include type-setting(-1);
		padding: $gutter/2;
		margin: $gutter/4;
		margin-top: $gutter;
		//background-color: $dark-grey;
		border: 1px solid $dark-grey;
		flex: 0 100%;
	}

	&__label {
		flex: 45%;
		text-align: left;
		align-self: flex-start;
		//display: none;
		@include type-setting(-1);
		color: $dark-grey;
		margin: $gutter/4;
		margin-top: $gutter/2;

		&:last-of-type {
			flex: 100%;
		}
	}

	#newsletter-form-confirm-sign-up {
		width: 0;
		height: 0;
		margin: 0;
		padding: 0;
		opacity: 0;
	}
}

.newsletter-form--footer {
	max-width: 47rem;
	padding: 0;

	.newsletter-form-inner {
		font-size: 0.85rem;
		justify-content: space-around;
		margin: auto;
		padding: 0;
	}

	.newsletter-form__headline {
		order: 0;
		color: #fff;
		margin-bottom: $gutter/2;
		margin-top: $gutter/2;
	}

	.newsletter-form__label {
		color: #fff;
		flex: 0 1 100%;
		margin: 0;
		padding: 0 $gutter/2;

		@include for-size(tablet-landscape-up) {
			order: 1;
			flex: 0 1 42%;

			&:last-of-type {
				flex: 50%;
			}
		}
	}

	.newsletter-form__input {
		border: 1px solid $primary-color;
		flex: 100%;

		@include for-size(tablet-landscape-up) {
			order: 2;
			flex: 1 calc(36% - #{$gutter/2}) !important;
		}
	}

	.newsletter-form__checkbox-box {
		width: 100%;
		display: flex;
		justify-content: center;

		@include for-size(tablet-landscape-up) {
			order: 4;
		}
	}

	.newsletter-form__checkbox {
		&::before {
			border: 1px solid #fff;
		}

		&::after {
			color: #fff;
		}
	}

	.newsletter-form__checkbox-label {
		flex: unset;
		width: auto;
	}

	.newsletter-form__button {
		flex: 0 50%;
		margin: $gutter auto;
		border: 1px solid #fff;
		align-self: center;

		@include for-size(tablet-landscape-up) {
			order: 3;
			margin: 0 $gutter/4;
			flex: 0 calc(20% - #{$gutter/2});
		}

		&:hover {
			background-color: #fff;
		}

		&:focus {
			color: $dark-grey;
			outline: 2px dotted $dark-grey;
		}
	}

	// .newsletter-form__valid {
	// 	color: #fff;
	// 	background-color: $primary-color;
	// 	border-color: currentColor;
    //
	// 	@include for-size(tablet-landscape-up) {
	// 		order: 4;
	// 	}
	// }
    //
	// .newsletter-form__error {
	// 	color: $primary-color;
	// 	background-color: #fff;
    //
	// 	@include for-size(tablet-landscape-up) {
	// 		order: 5;
	// 	}
	// }
}



////////////////////////////////////
//  Newsletter unsubscribe form  //
///////////////////////////////////

// Set to `$gutter` to use a visible gutter between the input field and the
// submit button
$form-gutter: 0;

.form-row {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	margin: 0 -$form-gutter;

	> .form-field {
		flex: 1;
		margin: 0 0.5 * $form-gutter;

		label {
			text-align: left;
			display: inline-block;
			width: 100%;
		}

		> * {
			margin: 0;
		}
	}

	> .form-field--button {
		flex: 0 0 auto;
	}
}



// Cobra: Validation Error Popup:
#MNR_ActivationLinkPopupBG {

	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 4; // Above header and footer 

	.mnr_popup {

		background-color: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 1.5rem;
		color: $text-color;
		font-size: 1rem;
		line-height: 1.3em;
	}

	p {
		text-align: center !important;
	}

	td {
		background-color: transparent !important;

		&.SignUpButton {
			background-color: $primary-color !important;
			color: #fff !important;
			border-radius: 0 !important;
		}
	}
}
